import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Typography,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { appTheme, useSession } from '..';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { appThemeSettings } from '../const/theme';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EngineRoomLogoNoText } from '../../components/EngineRoomLogos';

interface HeaderProps {
  menuOpen: boolean;
  sidebarWidth: number;
  toggleSidebar: Function;
}

function Header({ menuOpen, sidebarWidth, toggleSidebar }: HeaderProps) {
  const { logout, user } = useSession();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  // Get the current headerLogo
  let HeaderLogo = appThemeSettings.headerLogo;

  // Only change the logo when closing sidebar if it's EngineRoomLogoNoText
  if (HeaderLogo === EngineRoomLogoNoText) {
    HeaderLogo = menuOpen
      ? appThemeSettings.headerLogoOpen
      : appThemeSettings.headerLogo;
  }

  // Set the width of the logo container based on the logo type
  const logoWidth =
    HeaderLogo === EngineRoomLogoNoText
      ? menuOpen
        ? `${sidebarWidth}px`
        : '40px' // If the logo is EngineRoomLogoNoText
      : menuOpen
      ? `${sidebarWidth}px`
      : '0px'; // If the logo is not EngineRoomLogoNoText

  // Set justifyContent based on the logo type
  const logoAlignment = HeaderLogo === EngineRoomLogoNoText ? 'left' : 'center';

  return (
    <>
      <AppBar
        id="header"
        position="fixed"
        elevation={1}
        sx={{
          paddingY: '8px',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: appTheme.palette.primary.main,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: logoWidth,
              display: 'flex',
              justifyContent: logoAlignment,
            }}
          >
            <HeaderLogo />
          </div>

          <IconButton
            onClick={() => toggleSidebar()}
            color="inherit"
            sx={{
              background: 'rgba(0,0,0,0.1)',
              '&:hover': {
                background: 'rgba(0,0,0,0.2)',
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          <span style={{ flex: 1 }}></span>

          <Typography
            mr={3}
            sx={{ opacity: 0.5 }}
            color={appTheme.palette.primary.contrastText}
          >
            {user?.bcServiceProviderId}
          </Typography>
          <Button
            variant="text"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(e) => setOpen(true)}
            sx={{
              marginRight: 2,
              color: appTheme.palette.primary.contrastText,
            }}
          >
            <LockPersonOutlinedIcon sx={{ marginRight: 2 }} />
            {user?.userName}
          </Button>
        </div>
      </AppBar>

      <Menu
        anchorEl={document.getElementById('header')}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <MenuItem
          onClick={() => {
            setOpen(false);
            navigate('/settings');
          }}
        >
          <ListItemIcon>
            <HelpOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Help
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            setOpen(false);
            navigate("/settings");
          }}
        >
          <ListItemIcon>
            <SettingsOutlined fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
        <Divider />
        <MenuItem
          onClick={() => {
            setOpen(false);
            logout(true);
          }}
          sx={{
            color: appTheme.palette.error.main,
            minWidth: '150px',
          }}
        >
          <ListItemIcon>
            <LockOpenIcon fontSize="small" color="error" />
          </ListItemIcon>
          Log out
        </MenuItem>
      </Menu>
    </>
  );
}

export default Header;
