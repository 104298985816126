import EngineRoomLogoWhiteImage from '../lib/svg/EngineRoom_Logo_White.svg';
import EngineRoomLogoBlackImage from '../lib/svg/EngineRoom_Logo_Black.svg';
import EngineRoomLogoWhiteStackedImage from '../lib/svg/EngineRoom_Logo_White_Stacked.svg';
import EngineRoomLogoBlackStackedImage from '../lib/svg/EngineRoomLogo_Black_Stacked.svg';
import EngineRoomLogoNoTextImage from '../lib/svg/EngineRoom_Logo_NoText.svg';

export type EngineRoomLogoProps = {
  width?: string;
  height?: string;
};

export const EngineRoomLogoWhite = (props: EngineRoomLogoProps) => {
  return (
    <img
      src={EngineRoomLogoWhiteImage}
      alt="Engine Room Logo"
      {...props}
      style={{ paddingLeft: '5%', paddingRight: '5%', width: '90%' }}
    />
  );
};

export const EngineRoomLogoNoText = (props: EngineRoomLogoProps) => {
  return (
    <img
      src={EngineRoomLogoNoTextImage}
      alt="Engine Room Logo"
      {...props}
      style={{ paddingLeft: '5%', paddingRight: '5%', width: '100%' }}
    />
  );
};

export const EngineRoomLogoBlack = (props: EngineRoomLogoProps) => {
  return (
    <img src={EngineRoomLogoBlackImage} alt="Engine Room Logo" {...props} />
  );
};

export const EngineRoomLogoWhiteStacked = (props: EngineRoomLogoProps) => {
  return (
    <img
      src={EngineRoomLogoWhiteStackedImage}
      alt="Engine Room Logo"
      {...props}
    />
  );
};

export const EngineRoomLogoBlackStacked = (props: EngineRoomLogoProps) => {
  return (
    <img
      src={EngineRoomLogoBlackStackedImage}
      alt="Engine Room Logo"
      style={{ paddingLeft: '15%', paddingRight: '15%' }}
    />
  );
};
